
    export const portfolioData = [
        {
            "cover": require("../../portfolio/2/svm.JPG"),
            "title": "Scotts Valley Magnetics Website",
            "description": "Company website developed for electronics manufacturing company Scotts Valley Magnetics.",
            "stack": "ReactJS, JSX",
            "url": "https://scottsvalleymagnetics.netlify.app/",
            "src": "https://github.com/HorvathTeddy/svmagnetics"
        },
        {
            "cover": require("../../portfolio/1/ManualDynamics.png"),
            "title": "Manual Dynamics",
            "description": "Website developed for a licensed Massage Therapist in Humboldt, CA",
            "stack": "Webflow",
            "url": "https://www.manualdynamics.com/",
        },
        {
            "cover": require("../../portfolio/7/mervMob1.png"),
            "title": "MervMob",
            "description": "Website developed for the NFT project 'MervMob' ",
            "stack": "ReactJS, JSX",
            "url": "https://mervmob.com/",
        },
        {
            "cover": require("../../portfolio/8/KargesFlooring.png"),
            "title": "Karges Flooring",
            "description": "Company website developed for licensed flooring installers in Humboldt, CA ",
            "stack": "ReactJS",
            "url": "https://www.kargesflooring.com/",
        },
        {
            "cover": require("../../portfolio/9/megancollinsartworks.jpg"),
            "title": "Megan Collins Artworks",
            "description": "Art gallery, shop, and blog for the artist Megan Collins based in Naalehu, Hawaii  ",
            "stack": "WordPress, WooCommerce",
            "url": "https://www.megancollinsartworks.com/",
        }]


        // {
        //     "cover": require("../../portfolio/8/startupucr.JPG"),
        //     "title": "Karges Flooring",
        //     "description": "Company website developed for licensed flooring installers in Humboldt, CA ",
        //     "stack": "ReactJS",
        //     "url": "https://www.kargesflooring.com/",
        // }]








    //     {
    //         "cover": require("../../portfolio/3/uhr.PNG"),
    //         "title": "Uhr Mobile Delivery",
    //         "description": "On demand delivery service with a user and driver application.",
    //         "stack": "React Native, AWS Amplify",
    //         "url": "https://github.com/HorvathTeddy/SprintDeliveries",
    //         "src": "https://github.com/HorvathTeddy/SprintDeliveries"
    //     },
    //     {
    //         "cover": require("../../portfolio/4/tinder.JPG"),
    //         "title": "Tinder Clone",
    //         "description": "Full stack tinder clone.",
    //         "stack": "ReactJS, MongoDB, Express, NodeJS",
    //         "url": "https://tinder-clone-e5610.firebaseapp.com/",
    //         "src": "https://github.com/HorvathTeddy/tinder-clone"
    //     },
    //     {
    //         "cover": require("../../portfolio/5/taskmaster.JPG"),
    //         "title": "Task-R-Us",
    //         "description": "A full stack task manager application for business owners",
    //         "stack": "React Native, MongoDB, Express, NodeJS",
    //         "url": "https://github.com/HorvathTeddy/taskmaster",
    //         "src": "https://github.com/HorvathTeddy/taskmaster"
    //     },
    //     {
    //         "cover": require("../../portfolio/6/pongjs.JPG"),
    //         "title": "Pong",
    //         "description": "Pong in web browser with AI opponent",
    //         "stack": "JavaScript",
    //         "url": "https://pong-in-js.netlify.app/",
    //         "src": "https://github.com/HorvathTeddy/pong-js"
    //     },
    //     {
    //         "cover": require("../../portfolio/9/1.jpg"),
    //         "title": "Plumbing Contractor Flyer",
    //         "description": "Contractor flyers for Farmers Insurance Company",
    //         "stack": "Adobe Photoshop, Canva",
    //         "url": "https://ibb.co/9sfpdw8",
    //         "src": "https://ibb.co/9sfpdw8"
    //     },
    //     {
    //         "cover": require("../../portfolio/9/2.jpg"),
    //         "title": "Landscaping Contractor Flyer",
    //         "description": "Contractor flyers for Farmers Insurance Company",
    //         "stack": "Adobe Photoshop, Canva",
    //         "url": "https://ibb.co/bdyscC9",
    //         "src": "https://ibb.co/bdyscC9"
    //     },
    //     {
    //         "cover": require("../../portfolio/9/3.jpg"),
    //         "title": "Concrete Contractor Flyer",
    //         "description": "Contractor flyers for Farmers Insurance Company",
    //         "stack": "Adobe Photoshop, Canva",
    //         "url": "https://ibb.co/tbtcwGm",
    //         "src": "https://ibb.co/tbtcwGm"
    //     },
    //     {
    //         "cover": require("../../portfolio/9/4.jpg"),
    //         "title": "Electrician Contractor Flyer",
    //         "description": "Contractor flyers for Farmers Insurance Company",
    //         "stack": "Adobe Photoshop, Canva",
    //         "url": "https://ibb.co/Kss4HRb",
    //         "src": "https://ibb.co/Kss4HRb"
    //     } {
    //     "cover": require("../../portfolio/10/HitchedFlims.png"),
    //     "title": "HitchedFilms",
    //     "description": "Comnpany website developed for Wedding Videography in Humboldt, CA",
    //     "stack": "Webflow",
    //     "url": "https://www.hitchedfilms.com/",
    // },
    // ]
